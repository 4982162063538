import React from 'react';
// import sections
import PrivacyPolicy from '../components/sections/PrivacyPolicy';

const SearchboxPrivacyPolicy = () => {

  return (
    <>
      <PrivacyPolicy />
    </>
  );
}

export default SearchboxPrivacyPolicy;