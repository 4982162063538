import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Software development and technology as a tool to engage our community.',
    paragraph: 'Engaging community is essential to any successful business. We use software development and technology with you to do so.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Our focus on community
                  </div>
                <h3 className="mt-0 mb-12">
                  Community driven platforms
                  </h3>
                <p className="m-0">
                  Our platforms are created and designed to benefit all members of our community. Our platforms are accessible, intentional, and offer great user experience. We pride ourselves on a great return on investment to our partners,  as we engage in community outreach with our technology.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item" style={{border: '4px solid #5c82dd'}}>
                <Image
                  src={require('./../../assets/images/community.jpg')}
                  alt="Different color board game pieces."
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Our focus on technology
                  </div>
                <h3 className="mt-0 mb-12">
                  Highest quality software development
                  </h3>
                <p className="m-0">
                  We understand the impact technology and how essential it is to reach communities. We are looking for partners to engage with on a common goal by offering the highest quality software development and technology. Together we can come together to make a difference in our communities through innovation and outreach.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item" style={{border: '4px solid #5c82dd'}}>
                <Image
                  src={require('./../../assets/images/web-technology.jpg')}
                  alt="A computer screen with computer code on it."
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Partner with us
                  </div>
                <h3 className="mt-0 mb-12">
                  Our goal, your teamwork
                  </h3>
                <p className="m-0">
                  We want to partner with others to make a real difference. Through software development and technology, we can work together to engage with our community. Let's collaborate on how we can benefit the community together.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item" style={{border: '4px solid #5c82dd'}}>
                <Image
                  src={require('./../../assets/images/teamwork-goal.jpg')}
                  alt="A lightbulb and chalk drawing indicating ideas."
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
