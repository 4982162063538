import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types
}

const defaultProps = {
  children: null,
  ...SectionProps.defaults
}

const GenericSection = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          {children}
          <p>
            <strong>Privacy Policy</strong><br></br>
            <em>Last updated May 23, 2024.</em>
          </p>
          <p>
            This Privacy Policy describes how SEARCHBOX LLC ("we", "us", or "our") collects, uses, and protects any information that you provide while using our single-page application (SPA) for marketing purposes.
          </p>
          <p>
            <strong>Information We Do Not Collect</strong><br></br>
            We do not collect any personal information from users of our SPA. We do not use cookies or any other tracking technologies to gather information about users' browsing activities.
          </p>
          <p>
            <strong>Links to Our Diversity Job Network Sites</strong><br></br>
            While using our SPA, you may encounter links to job sites from our <a href="/diversity-job-network" style={{ textDecoration: 'underline' }}>Diversity Job Network</a> owned by our LLC. Please note that each of these job sites has its own privacy policy. We encourage you to review the privacy policies of these job sites before providing any personal information.
          </p>
          <p>
            <strong>Third-Party Services</strong><br></br>
            Our SPA may also contain links to third-party websites or services. We do not control or have access to the information collected by these third-party services. We encourage you to review the privacy policies of these third-party services before providing any personal information.
          </p>
          <p>
            <strong>Children's Privacy</strong><br></br>
            Our SPA is not directed to individuals under the age of 13, and we do not knowingly collect personal information from children under 13. If you believe that a child under 13 has provided us with personal information, please contact us immediately, and we will take steps to remove such information from our systems.
          </p>
          <p>
            <strong>Accessibility</strong><br></br>
            We are committed to ensuring that our website is accessible to everyone. If you encounter any accessibility barriers or have suggestions for improvement, please <a href="mailto:info@searchbox.tech" style={{ textDecoration: 'underline' }}>contact us</a>.
          </p>
          <p>
            <strong>Changes to This Privacy Policy</strong><br></br>
            We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this Privacy Policy periodically for any changes. The date of the last update will be indicated at the top of this Privacy Policy.
          </p>
          <p>
            <strong>Contact Us</strong><br></br>
            If you have any questions or <a href="mailto:info@searchbox.tech" style={{ textDecoration: 'underline' }}>contact us</a>.
          </p>
        </div>
      </div>
    </section>
  );
}

GenericSection.propTypes = propTypes;
GenericSection.defaultProps = defaultProps;

export default GenericSection;