import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';


const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Diversity Job Network',
    paragraph: 'A network of specialized diversity-focused job websites that can help reinforce diversity recruitment strategies.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            
            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner" style={{ backgroundColor: 'white' }}>
                <div className="testimonial-item-footer text-xs mb-0">
                  <span className="text-color-low"> 
                    <a href="https://africanamericanjobsearch.com">
                      <Image
                        src='https://diversity-job-boards-8.s3-us-west-1.amazonaws.com/africanamericanjobsearch.svg'
                        alt="African American Job Search logo"
                        width={528}
                        height={396} /> 
                    </a>
                  </span>
                </div>
              </div>
            </div>
            
            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner" style={{ backgroundColor: 'white' }}>
                <div className="testimonial-item-footer text-xs mb-0">
                  <span className="text-color-low"> 
                    <a href="https://hispanicinjobs.com">
                      <Image
                        src='https://diversity-job-boards-8.s3-us-west-1.amazonaws.com/hispanicinjobs.svg'
                        alt="Hispanic Inclusion Jobs logo"
                        width={528}
                        height={396} /> 
                    </a>
                  </span>
                </div>
              </div>
            </div>
            
            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner" style={{ backgroundColor: 'white' }}>
                <div className="testimonial-item-footer text-xs mb-0">
                  <span className="text-color-low"> 
                    <a href="https://asianjobsearch.com">
                      <Image
                        src='https://diversity-job-boards-8.s3-us-west-1.amazonaws.com/asianjobsearch.svg'
                        alt="Asian Job Search logo"
                        width={528}
                        height={396} /> 
                    </a>
                  </span>
                </div>
              </div>
            </div>
            
            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner" style={{ backgroundColor: 'white' }}>
                <div className="testimonial-item-footer text-xs mb-0">
                  <span className="text-color-low"> 
                    <a href="https://womeninjobs.com">
                      <Image
                        src='https://diversity-job-boards-8.s3-us-west-1.amazonaws.com/womeninjobs.svg'
                        alt="Women Inclusion Jobs logo"
                        width={528}
                        height={396} /> 
                    </a>
                  </span>
                </div>
              </div>
            </div>
            
            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner" style={{ backgroundColor: 'white' }}>
                <div className="testimonial-item-footer text-xs mb-0">
                  <span className="text-color-low"> 
                    <a href="https://diversityinjobs.com">
                      <Image
                        src='https://diversity-job-boards-8.s3-us-west-1.amazonaws.com/diversityinjobs.svg'
                        alt="Diversity Inclusion Jobs logo"
                        width={528}
                        height={396} /> 
                    </a>
                  </span>
                </div>
              </div>
            </div>
            
            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner" style={{ backgroundColor: 'white' }}>
                <div className="testimonial-item-footer text-xs mb-0">
                  <span className="text-color-low"> 
                    <a href="https://seniorstowork.com">
                      <Image
                        src='https://diversity-job-boards-8.s3-us-west-1.amazonaws.com/seniorstowork.svg'
                        alt="Seniors to work logo"
                        width={528}
                        height={396} /> 
                    </a>
                  </span>
                </div>
              </div>
            </div>
            
            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner" style={{ backgroundColor: 'white' }}>
                <div className="testimonial-item-footer text-xs mb-0">
                  <span className="text-color-low"> 
                    <a href="https://blackinjobs.com">
                      <Image
                        src='https://diversity-job-boards-8.s3-us-west-1.amazonaws.com/blackinjobs.svg'
                        alt="Black Inclusion Jobs logo"
                        width={528}
                        height={396} /> 
                    </a>
                  </span>
                </div>
              </div>
            </div>
            
            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner" style={{ backgroundColor: 'white' }}>
                <div className="testimonial-item-footer text-xs mb-0">
                  <span className="text-color-low"> 
                    <a href="https://veteranjobcenter.com">
                      <Image
                        src='https://diversity-job-boards-8.s3-us-west-1.amazonaws.com/veteranjobcenter.svg'
                        alt="Veteran Job Center logo"
                        width={528}
                        height={396} /> 
                    </a>
                  </span>
                </div>
              </div>
            </div>
            
            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner" style={{ backgroundColor: 'white' }}>
                <div className="testimonial-item-footer text-xs mb-0">
                  <span className="text-color-low"> 
                    <a href="https://lgbtjobsearch.com">
                      <Image
                        src='https://diversity-job-boards-8.s3-us-west-1.amazonaws.com/lgbtjobsearch.svg'
                        alt="LGBT Job Search logo"
                        width={528}
                        height={396} /> 
                    </a>
                  </span>
                </div>
              </div>
            </div>
            
            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner" style={{ backgroundColor: 'white' }}>
                <div className="testimonial-item-footer text-xs mb-0">
                  <span className="text-color-low"> 
                    <a href="https://asianinjobs.com">
                      <Image
                        src='https://diversity-job-boards-8.s3-us-west-1.amazonaws.com/asianinjobs.svg'
                        alt="Asian Inclusion Jobs logo"
                        width={528}
                        height={396} /> 
                    </a>
                  </span>
                </div>
              </div>
            </div>
            
            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner" style={{ backgroundColor: 'white' }}>
                <div className="testimonial-item-footer text-xs mb-0">
                  <span className="text-color-low"> 
                    <a href="https://disabledjobseekers.com">
                      <Image
                        src='https://diversity-job-boards-8.s3-us-west-1.amazonaws.com/disabledjobseekers.svg'
                        alt="Disabled Job Seekers logo"
                        width={528}
                        height={396} /> 
                    </a>
                  </span>
                </div>
              </div>
            </div>
            
            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner" style={{ backgroundColor: 'white' }}>
                <div className="testimonial-item-footer text-xs mb-0">
                  <span className="text-color-low"> 
                    <a href="https://seniorsinjobs.com">
                      <Image
                        src='https://diversity-job-boards-8.s3-us-west-1.amazonaws.com/seniorsinjobs.svg'
                        alt="Senior Inclusion Jobs logo"
                        width={528}
                        height={396} /> 
                    </a>
                  </span>
                </div>
              </div>
            </div>
            
            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner" style={{ backgroundColor: 'white' }}>
                <div className="testimonial-item-footer text-xs mb-0">
                  <span className="text-color-low"> 
                    <a href="https://abilitiesinjobs.com">
                      <Image
                        src='https://diversity-job-boards-8.s3-us-west-1.amazonaws.com/abilitiesinjobs.svg'
                        alt="Disability Inclusion Jobs logo"
                        width={528}
                        height={396} /> 
                    </a>
                  </span>
                </div>
              </div>
            </div>
            
            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner" style={{ backgroundColor: 'white' }}>
                <div className="testimonial-item-footer text-xs mb-0">
                  <span className="text-color-low"> 
                    <a href="https://usdiversityjobsearch.com">
                      <Image
                        src='https://diversity-job-boards-8.s3-us-west-1.amazonaws.com/usdiversityjobsearch.svg'
                        alt="US Diversity Job Search logo"
                        width={528}
                        height={396} /> 
                    </a>
                  </span>
                </div>
              </div>
            </div>
            
            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner" style={{ backgroundColor: 'white' }}>
                <div className="testimonial-item-footer text-xs mb-0">
                  <span className="text-color-low"> 
                    <a href="https://lgbtqinjobs.com">
                      <Image
                        src='https://diversity-job-boards-8.s3-us-west-1.amazonaws.com/lgbtqinjobs.svg'
                        alt="LGBTQ Inclusion Jobs logo"
                        width={528}
                        height={396} /> 
                    </a>
                  </span>
                </div>
              </div>
            </div>
            
            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner" style={{ backgroundColor: 'white' }}>
                <div className="testimonial-item-footer text-xs mb-0">
                  <span className="text-color-low"> 
                    <a href="https://hispanicjobexchange.com">
                      <Image
                        src='https://diversity-job-boards-8.s3-us-west-1.amazonaws.com/hispanicjobexchange.svg'
                        alt="Hispanic Job Exchange logo"
                        width={528}
                        height={396} /> 
                    </a>
                  </span>
                </div>
              </div>
            </div>
            
             <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="">
                  <a href="https://searchbox.tech">
                      <Image
                        src={require('./../../assets/images/searchbox.svg')}
                        alt="Searchbox logo"
                        width={125}
                        height={125} /> 
                    </a>
                  <p className="text-sm mb-0">
                      Interested in posting jobs? 
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mb-0 has-top-divider">
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="mailto:info@searchbox.tech" className="button button-primary button-wide-mobile button-block" style={{ color: 'white' }}>Contact us</a>
                  </span>
                </div>
              </div>
            </div>
            
            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="">
                  <p className="text-sm mb-0">
                      </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;