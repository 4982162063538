import React from 'react';
// import sections
import DiversityJobNetwork from '../components/sections/DiversityJobNetwork';

const SearchboxDiversityJobNetwork = () => {

  return (
    <>
      <DiversityJobNetwork />
    </>
  );
}

export default SearchboxDiversityJobNetwork;
